<template>
  <p class="clearfix mb-0">
    <span class="float-md-left d-block d-md-inline-block mt-25">
      COPYRIGHT © {{ new Date().getFullYear() }}
      <b-link class="ml-25" href="https://psikologi.usm.ac.id" target="_blank">Fakultas Psikologi USM </b-link>
      <span class="d-none d-sm-inline-block">, All rights Reserved</span>
    </span>

    <small class="float-md-right d-none d-md-block">dev by psikologi-tect
    </small>
  </p>
</template>

<script>
  import {
    BLink
  } from 'bootstrap-vue'

  export default {
    components: {
      BLink,
    },
  }
</script>
